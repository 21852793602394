import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import FiveStars from "./FiveStars";
import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";

const TestimonialsGrid = ({
  testimonialsGridObject,
  classNameTitle = "",
  classNameListText = "",
  classNameListTitle = "",
  whitespaceList = "pre-line",
}) => {
  return (
    <>
      {testimonialsGridObject?.title && (
        <RenderMarkdown
          markdownContent={testimonialsGridObject.title}
          whitespace="normal"
          className={`text-center mb-5 sm:mb-10 space-y-3 ${classNameTitle}`}
        />
      )}
      <ul className="flex flex-col items-center lg:items-start lg:flex-row lg:flex-wrap mx-auto">
        {testimonialsGridObject.list.map((el, index) => {
          return (
            <li
              className={`${
                index > 4 && "hidden lg:flex"
              } flex flex-col space-y-10 lg:w-1/2 2xl:w-1/3 sm:p-5 max-w-2xl`}
            >
              <div className="flex items-center space-x-3 transform -translate-x-2">
                <PreviewCompatibleImage imageObject={el.imageObject} />
                <div>
                  <RenderMarkdown
                    markdownContent={el.author}
                    className={`${classNameListTitle} w-full`}
                    isMarkdown={true}
                    whitespace="normal"
                  />
                  <FiveStars />
                </div>
              </div>

              {/* w-5/6 to ensure that Icons have enough space to stay at the same size together */}
              <div className="my-auto xl:w-auto">
                <RenderMarkdown
                  markdownContent={el.description}
                  className={classNameListText}
                  isMarkdown={true}
                  whitespace={whitespaceList}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default TestimonialsGrid;
