import React from "react";

import { ReactComponent as Star } from "../img/imported_with_webpack/star-solid.svg";

const FiveStars = ({className=""}) => {
  return (
    <div className={`flex flex-row space-x-3 ${className}`}>
      <Star className="w-5 sm:w-8 h-5 sm:h-8 text-brand-gold fill-current"/>
      <Star className="w-5 sm:w-8 h-5 sm:h-8 text-brand-gold fill-current"/>
      <Star className="w-5 sm:w-8 h-5 sm:h-8 text-brand-gold fill-current"/>
      <Star className="w-5 sm:w-8 h-5 sm:h-8 text-brand-gold fill-current"/>
      <Star className="w-5 sm:w-8 h-5 sm:h-8 text-brand-gold fill-current"/>
    </div>
  );
};

export default FiveStars;
