import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";
import { StaticImage } from "gatsby-plugin-image";

const FinalText = ({ finalTextObject, className="" }) => {
  return (
    <div className={`relative max-w-2xl mx-auto ${className}`}>
      <div className="space-y-10">
        <RenderMarkdown
          markdownContent={finalTextObject.title}
          whitespace="normal"
          className="text-center"
        />
        <div>
          {/* style to fix safari overflow bug */}
          <div
            className="w-64 rounded-full overflow-hidden mx-auto bg-white"
            style={{ transform: "translateZ(0)" }}
          >
            <PreviewCompatibleImage imageObject={finalTextObject.imageObject} />
          </div>
        </div>
        <RenderMarkdown markdownContent={finalTextObject.description} />
      </div>
      <StaticImage
        className="w-40"
        src="../img/unterschrift-peter-beer.png"
        alt="Unterschrift Peter Beer"
      />
    </div>
  );
};

export default FinalText;
