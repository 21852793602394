import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import Cta from "./CTA";

const Guarantee = ({ guaranteeObject }) => {
  return (
    <div className="space-y-5 sm:space-y-10">
      <RenderMarkdown
        markdownContent={guaranteeObject.title}
        className="text-center mb-5 sm:mb-10"
      />
      <div className="flex flex-col sm:flex-row items-center sm:justify-center sm:items-start sm:space-x-5">
        <div className="w-28 sm:w-40 h-28 sm:h-40 shrink-0 rounded-full bg-brand-green flex items-center justify-center text-center text-white text-2xl sm:text-3xl transform -translate-y-5">
          30
          <br />
          Tage
        </div>
        <RenderMarkdown markdownContent={guaranteeObject.description} />
      </div>
      <Cta ctaObject={guaranteeObject.ctaObject} />
    </div>
  );
};

export default Guarantee;
